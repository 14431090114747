






























































import AppDialog from "@/components/Navigation/AppDialog.vue";
import { WatchLoading } from "@/decorators/Loading";
import { Functions } from "@/firebase";
import { CurrentUserMixin, EmitClose, LoadingMixin } from "@/mixins/Helpers";
import { httpsCallable } from "@firebase/functions";
import {
  Membership,
  CreateSubscriptionRequest,
  Subscription,
  BaseUser,
  AppUser
} from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import {
  GetProrationPrice,
  MembershipResponse,
  ProrationPriceResponse
} from "@sportango/backend";
import { Prop } from "vue-property-decorator";

@Component({
  name: "subscribe",
  components: {
    AppDialog
  }
})
export default class Subscribe extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  EmitClose
) {
  @Prop({ required: false, default: null })
  selectedUser!: BaseUser | null;

  amountDueToday: number | null = null;
  errorMessage: string | null = null;

  get user(): BaseUser | AppUser | null {
    if (this.selectedUser) {
      return this.selectedUser;
    } else if (this.currentUser) {
      return this.currentUser;
    }
    return null;
  }

  get selectedMembership(): Membership | undefined {
    return this.$store.getters.memberships.find(
      (m) => m.id === this.$route.params.membershipId
    );
  }

  get userMembership(): Membership | undefined {
    if (this.userSubscription) {
      return this.$store.getters.memberships.find((m) =>
        this.userSubscription?.products.find((p) => p.productId === m.id)
      );
    }
    return undefined;
  }

  get userSubscription(): Subscription | undefined {
    return this.$store.getters.subscriptions.find(
      (s) => s.stripeCustomerId === this.user?.stripeCustomerId
    );
  }

  get title(): string {
    if (this.userMembership) {
      return "Update Membership";
    } else {
      return "Start Membership";
    }
  }

  get amountText(): string {
    if (typeof this.amountDueToday !== "number") {
      return "$--.--";
    } else {
      return `$${(Math.abs(this.amountDueToday) / 100).toFixed(2)}`;
    }
  }

  get amountDescriptor(): string {
    if (typeof this.amountDueToday === "number") {
      if (this.amountDueToday < 0) {
        return "Amount to be added as credit:-";
      } else {
        return "Amount to be paid now:-";
      }
    } else {
      return "";
    }
  }

  @WatchLoading()
  async createSubscription() {
    if (
      this.user &&
      this.user.stripeCustomerId &&
      this.$store.getters.merchantInfo?.merchantId &&
      this.selectedMembership
    ) {
      const payload: CreateSubscriptionRequest = {
        uid: this.user.uid,
        accountId: this.$store.getters.merchantInfo.merchantId,
        customerId: this.user.stripeCustomerId,
        priceId: this.selectedMembership.price.id,
        existingSubscription: this.userSubscription?.id
      };
      const { data: response } = await httpsCallable<
        CreateSubscriptionRequest,
        MembershipResponse
      >(
        Functions,
        "createSubscription"
      )(payload);
      if (response.isSuccess) {
        await this.$store.dispatch("getUsersById", this.user?.uid);
        await this.$store.dispatch("getAllMemberships");
        await this.$store.dispatch(
          "getUserSubscription",
          this.user?.stripeCustomerId
        );
        this.close();
      } else {
        this.errorMessage = response.message || "Something went wrong";
      }
    }
  }

  @WatchLoading()
  async mounted() {
    await this.$store.dispatch("getAllMemberships");
    await this.$store.dispatch(
      "getUserSubscription",
      this.user?.stripeCustomerId
    );
    await this.getProratedPrice();
  }

  @WatchLoading()
  async getProratedPrice() {
    if (
      this.$store.getters.merchantInfo?.merchantId &&
      this.user?.stripeCustomerId &&
      this.selectedMembership
    ) {
      const { data } = await httpsCallable<
        GetProrationPrice,
        MembershipResponse | ProrationPriceResponse
      >(
        Functions,
        "getProratedPrice"
      )({
        newPriceId: this.selectedMembership.price.id,
        accountId: this.$store.getters.merchantInfo.merchantId,
        customerId: this.user.stripeCustomerId,
        existingSubscription: this.userSubscription?.id
      });
      if ((data as MembershipResponse).message !== undefined) {
        this.errorMessage =
          (data as MembershipResponse).message || "Something went wrong";
      } else {
        this.amountDueToday = (data as ProrationPriceResponse).amountDueToday;
      }
    }
  }
}
